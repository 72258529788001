/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { 
  INotificationPressureInjuryControlData,
  INotificationPressureInjuryControlPacient,
  Notification,
} from "app/models/notification"
import { 
  Button,
  Form,
  Col,
  Row,
  Modal
} from "react-bootstrap";
import {
  Card,
  CardContent
} from "@material-ui/core"
import { Pacient } from "app/models/pacient";
import { convertDateTimeToTime, convertDateTimeToTimeNoSeconds } from "_metronic/_helpers";
import { PermanenceByPositionBarChart } from "app/modules/Charts";
import { IMonitoringPermanenceByPosition } from "app/models/monitoring";
import { FormTextField } from "app/modules/Globals";
// import { getPositionTranslation, POS_LIST } from "app/modules/Monitoring/_helpers";
import { PositionType, PositionTypes } from "app/models/position";
import { pressureInjuryChange } from "api/monitoring";
import { logToSentry } from "_metronic/_helpers/SentryHelper";
import FixedDecubitusClockTable from "app/modules/Monitoring/detail/views/FixedDecubitusClockTable";
import DynamicDecubitusClockTable from "app/modules/Monitoring/detail/views/DynamicDecubitusClockTable";
import { confirmPressureInjuryPositionChangeNotification } from "api/notifications";

interface ListItemProps {
  intl: any
  item: INotificationPressureInjuryControlPacient
  patientIndex: number
  notificationTs: number
}
const ListItem = (props: ListItemProps) => {
  const { intl, item } = props
  const pacient = new Pacient(item.monitoring.pacient!)
  const { interval, quietStart, quietStop } = item.monitoring.pressureInjury!
  const [isLoadingRequest, setIsLoadingRequest] = React.useState<boolean>(false)
  const [iniNextPosition, setIniNextPosition] = React.useState<PositionTypes | undefined>()

  // If the pacient has already confirmed the position switch, we should disable the button
  const { notificationTs, patientIndex } = props
  const [confirmedPositionSwitch, setConfirmedPositionSwitch] = React.useState<boolean>(item.confirmedPositionSwitch ?? false)

  var quietMessage = ""
  if (quietStart != null && quietStop != null && quietStart !== quietStop)
    quietMessage = intl.formatMessage(
      { id: 'NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.CONFIG.QUIET' },
      { start: `${quietStart}`.padStart(2, '0'), stop: `${quietStop}`.padStart(2, '0') }
    ) 

  function onChangeNextPosition(nextPosition: PositionTypes, setIsLoading: Function) {
    if (!(nextPosition in PositionType)) return

    setIsLoading(true)
    setIniNextPosition(nextPosition)
    pressureInjuryChange(item.monitoring!.id, nextPosition)
      .then(async () => {
        await confirmPressureInjuryPositionChangeNotification(notificationTs, patientIndex)

        // Update the object to avoid confirmation again, since we don't fetch
        // the notifications from the database again until the user refreshes the page
        item.confirmedPositionSwitch = true

        setConfirmedPositionSwitch(true) 
      })
      .catch((err) => logToSentry(err, 'failed to pressureInjuryChange -> PressureInjuryDialogList'))
      .finally(() => setIsLoading(false))
  }

  const PacientBlock = () => (
    <>
      <span className="text-success font-weight-bolder font-size-h6">
        <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.PACIENT" />
      </span>
      <span className="d-block text-muted font-weight-normal">
        <span className="font-size-lg">{ pacient.getFormattedName() ?? <FormattedMessage id="MONITORING.DETAIL_DIALOG.PACIENT_VIEW.NO_NAME" /> }</span>
      </span>
    </>
  )
  const PreventionBlock = () => (
    <>
      <span className="text-primary font-weight-bolder font-size-h6 mt-3">
        <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.CONFIG" />
      </span>
      <span className="d-block text-muted font-weight-normal pr-10">
        <span className="font-size-lg">{
          intl.formatMessage(
            { id: 'NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.CONFIG.CHANGE' },
            { min: interval, quiet: quietMessage }
          )
        }</span>
      </span>
    </>
  )
  const PermanenceBlock = () => (
    <>
      <span className="text-danger font-weight-bolder font-size-h6">
        <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.PERMANENCE" />
      </span>
      <span className="d-block text-muted font-size-sm">
        { 
          intl.formatMessage(
            { id: 'NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.PERMANENCE.SUBTITLE' },
            { duration: interval }
          )
        }
      </span>
      <PermanenceByPositionBarChart
        data={item.permanenceByPosition?.map((v: IMonitoringPermanenceByPosition) => {
          return {
            duration: v.duration,
            positionId: v.positionId
          }
        })}
        height={'70%'}
        yOffset={-20}
        />
    </>
  )
  const PositionBlock = () => (
    <>
      <span className="text-info font-weight-bolder font-size-h6">
        <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.POSITION" />
      </span>
      <Form.Row>
        <FormTextField
          {...props} lg={12} titleId="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.POSITION.CURRENT"
          overrideValue={item.clockPositionNow}
          disabled={true}
        />

        {/* <FormDropDown
          {...props} lg={12} titleId="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.POSITION.CURRENT"
          overrideValue={item.monitoring?.iniPosition ?? 'DDH'}
          hasPlaceholderOption={false} // New clock approach
          disabled={true}
          options={POS_LIST.map((posId) => {
            const translation = getPositionTranslation(posId)
            return { id: posId, label: `${intl.formatMessage({id:translation.intlShortId})} - ${intl.formatMessage({id:translation.intlId})}` }
          })}
          /> */}

        <FormTextField
          {...props} lg={12} titleId="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.POSITION.NEXT"
          overrideValue={item.clockPositionNext}
          disabled={true}
          isLoading={isLoadingRequest}
          /> 

        {/* <FormDropDown
          {...props} lg={12} titleId="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.POSITION.NEXT"
          handleChange={(v: any) => onChangeNextPosition(v.target.value, setIsLoadingRequest)}
          overrideValue={iniNextPosition}
          isLoading={isLoadingRequest}
          options={POS_LIST.map((posId) => {
            const translation = getPositionTranslation(posId)
            return { id: posId, label: `${intl.formatMessage({id:translation.intlShortId})} - ${intl.formatMessage({id:translation.intlId})}` }
          })}
          /> */}

        {!confirmedPositionSwitch ? (
          <Button variant="success" onClick={() => {
            onChangeNextPosition(item.clockPositionNext, setIsLoadingRequest)
          }} style={{ margin: "auto" }}>
            <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.POSITION.CONFIRM" values={
              {
                from: item.clockPositionNow,
                to: item.clockPositionNext
              }
            } />
          </Button>
        ) : (
          <>
            <Button variant="success" style={{ margin: "auto", cursor: "default" }} disabled={true} >
              <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.POSITION.CONFIRMED" />
            </Button>
            <span style={{ textAlign: "center", margin: "15px auto" }}>
              <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.POSITION.CONFIRMED.MESSAGE" values={
                { position: item.clockPositionNext }
              } />
            </span>
          </>
        )}

      </Form.Row>
      {/* TEMPORARLY DISABLED */}
      {/* <Form.Row>
        <Form.Label className="col-lg-12"><FormattedMessage id={"MONITORING.NEW_DIALOG.STEP.CONFIG.ALLOWED_POS"} /></Form.Label>
        {
          POS_LIST.map((posId) => {
            const translation = getPositionTranslation(posId)
            return (
              <FormSwitch
                key={posId}
                {...props}
                lg={4} xs={4}
                titleId={translation.intlShortId}
                tooltipId={translation.intlId}
                valueRef={`allowedPositions.${posId}`} 
                />
            )
          })
        }
      </Form.Row> */}
    </>
  )
  const NextChangeBlock = () => (
    <>
      <span className="text-primary font-weight-bolder font-size-h6">
        <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.NEXT" />
      </span>
      <span className="d-block text-muted font-weight-normal">
        <span className="font-size-lg">
          {
            convertDateTimeToTime(item.nextAlert)
          }
        </span>
      </span>
    </>
  )

  // New block for clock approach
  const ClockTypeBlock = () => (
    <>
      <span className="text-primary font-weight-bolder font-size-h6">
        <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.CLOCK_TYPE" />
      </span>
      <span className="d-block text-muted font-weight-normal">
        <span className="font-size-lg">
          {
            intl.formatMessage(
              { id: `NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.CLOCK_TYPE.${item.monitoring.pressureInjury?.clockType?.toUpperCase() ?? "FIXED"}` }
            )
          }
        </span>
      </span>
    </>
  )

  // New block for clock approach
  const ClockTableBlock = () => (
    <>
      <span className="text-danger font-weight-bolder font-size-h6" style={{ display: "block", marginBottom: "5px" }}>
        <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.CLOCK_TABLE" />
      </span>
      {item.monitoring.pressureInjury?.clockType === "fixed" ? (
        <FixedDecubitusClockTable
          fullWidth={true}
          rowsLimit={2}
          currentIntervalFirst={true}
          showSelectInitialInterval={false}
          highlightCurrentInterval={true}
        />
      ) : (
        <DynamicDecubitusClockTable
          startMonitoringDate={new Date(item.monitoring.checkin)}
          fullWidth={true}
          rowsLimit={2}
          currentIntervalFirst={true}
          showSelectInitialInterval={false}
          highlightCurrentInterval={true}
        />
      )}
    </>
  )

  return (
    <Row key={pacient.id} className="mt-2 flex-grow-1">
      <Col xs={12} lg={12} className="px-2 mt-2">
        <Card className="card card-custom card-stretch rounded-lg">
          <CardContent>
            <Row>
              <Col xs={12} lg={3}>
                <PacientBlock />
                <div className="mt-5"></div>
                {/* <PreventionBlock />
                <div className="mt-5"></div> */}
                <ClockTypeBlock />
                {/* <div className="mt-5"></div> */}
                {/* <NextChangeBlock /> */}
              </Col>
              <Col xs={12} lg={5}>
                {/* <PermanenceBlock /> */}
                <ClockTableBlock />
              </Col>
              <Col xs={12} lg={4}>
                <PositionBlock />
              </Col>
            </Row>
          </CardContent>
        </Card>
      </Col>
    </Row>
  )
}

export interface PressureInjuryDialogListProps {
  intl: any
  notification: Notification
  onClose: () => {}
}

const PressureInjuryDialogList = (props: PressureInjuryDialogListProps) => {
  const { intl, notification, onClose } = props
  const data = notification.data as INotificationPressureInjuryControlData

  const modalRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal centered show={true} onHide={handleClose} size="lg" backdrop="static">
      <div ref={modalRef}>
        <Modal.Header closeButton>
          <Modal.Title className="flex-grow-1">
            <Row>
              <Col xs={8} lg={10}>
                
                <span className={`text-${notification.getSeverityStyle()}`}>
                  <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.TITLE" />
                </span>
                <span className="d-block text-primary font-size-sm font-weight-normal">
                  <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.DESCRIPTION" />
                </span>
              </Col>
              <Col xs={4} lg={2}>
                <span className={`text-info`}>
                  { convertDateTimeToTimeNoSeconds(notification.timestamp) }
                </span>
                <span className="d-block text-muted font-size-sm font-weight-normal">
                  <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.TIME" />
                </span>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Row>
                {
                  data.pacients.map((el, index) =>
                    <ListItem
                      key={el.monitoring.pacient!.id}
                      intl={intl}
                      item={el}
                      notificationTs={notification.timestamp}
                      patientIndex={index}
                      />
                  )
                }
              </Form.Row>
              </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{justifyContent:'center'}}>
          <Row style={{flex:1}}>
            <Col xs={6} lg={6} className={"text-left"} />
            <Col xs={6} lg={6} className={"text-right"}>
              <Button variant="danger" onClick={onClose}>
                <FormattedMessage id="NOTIFICATIONS.ALERTS.PRESSURE_INJURY.DIALOG.CLOSE" />
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </div>
    </Modal>
  )
}
export default injectIntl((PressureInjuryDialogList))
